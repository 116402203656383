import React, { useContext, useState } from "react";
// import { X, Camera } from "lucide-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Input, Modal, Label, ModalBody } from "reactstrap";
import WorkshopBanner from "../../../assets/images/wisecraft-white--.jpg";
import { Context } from "../../context/Store";
import { useNavigate } from "react-router-dom";

const WorkshopModal = ({ isOpen, onClose }) => {
    const [isToggled, setIsToggled] = useState(false);

    const navigate = useNavigate();

    const { state } = useContext(Context);

    const accessToken = state.user_data.accessToken;

    return (
        <Modal
            isOpen={isOpen}
            toggle={() => {
                onClose();
                setIsToggled(false);
            }}
            className="workshop-modal"
            backdrop={true}
        >
            <ModalBody>
                <div className="main-container ">
                    <div className="close-btn">
                        <FontAwesomeIcon
                            icon={faClose}
                            onClick={() => {
                                onClose();
                                setIsToggled(false);
                            }}
                        />
                    </div>
                    <div className="workshop-banner">
                        <img src={WorkshopBanner} alt="Workshop Banner" />
                    </div>
                    <div className="register-container">
                        <button className="register-content">
                            <a
                                href="https://rzp.io/rzp/cw7X3wvT"
                                target="_blank"
                            >
                                Register Now
                            </a>
                        </button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default WorkshopModal;
